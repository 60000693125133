import { ArrowLeftIcon } from '@heroicons/react/solid';
import Banner from 'components/banner';
import Button from 'components/button';
import Layout from 'components/layout';
import SEO from 'components/seo';
import { graphql, Link, navigate } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { clockIcon } from 'images';
import briefcaseIcon from 'images/briefcase.png';
import dollarIcon from 'images/dollar-gold.png';
import facebookIcon from 'images/facebook-color.svg';
import linkedIcon from 'images/linkedin.svg';
import locationIcon from 'images/location-gold.png';
import twitterIcon from 'images/twitter-color.svg';
import 'styles/job-detail.css';
import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
const bannerInfo = {
  height: '105px',
};

const JobDetail = ({ data }: any) => {
  const [input, setInput] = useState<any>({});
  const handleChange = (e: any) => setInput((prevState: any) => ({ ...prevState, [e.target.name]: e.target.value }));

  const handleSend = () => {
    if (input.yourName && input.yourName !== '' && input.Email && input.Email !== '' && input.Message && input.Message !== '') {
      alert('Cảm ơn bạn đã gửi phản hồi đến chúng tôi');
      // setInput({});
    } else {
      alert('Yêu cầu nhập đầy đủ thông tin!');
    }
  };
  const sendEmail = (e: any) => {
    e.preventDefault();
    console.log(e.target);
    emailjs.sendForm('service_cohwo2o', 'template_zbcpt7h', e.target, 'Rk_DznuC-wxnbSGfp').then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      },
    );
    e.target.reset();
  };

  return (
    <Layout>
      <SEO title="Career" />
      <div className="bg-white">
        <Banner height={bannerInfo.height} />
        <div className="container flex flex-col md:flex-row justify-between mx-auto py-[80px]">
          <div className="job-detail-container">
            <h1 className="text-[35px] md:text-[42px] font-bold mb-7">{data.mdx.frontmatter.title}</h1>
            <MDXRenderer>{data.mdx.body}</MDXRenderer>
          </div>
          <div className="w-[372px] md:ml-[60px]">
            <div className="flex items-center w-[312px] h-[40px] bg-[#F9F9F9] rounded-2xl mb-5">
              <img className="w-3 h-3 mx-[10px]" src={briefcaseIcon} alt="icon"></img>
              <span>{data.mdx.frontmatter.career}</span>
            </div>
            <div className="flex items-center w-[312px] h-[40px] bg-[#F9F9F9] rounded-2xl mb-5">
              <img className="w-3 h-3 mx-[10px]" src={clockIcon} alt="icon"></img>
              <span>{data.mdx.frontmatter.time}</span>
            </div>
            <div className="flex items-center w-[312px] h-[40px] bg-[#F9F9F9] rounded-2xl mb-5">
              <img className="w-3 h-3 mx-[10px]" src={dollarIcon} alt="icon"></img>
              <span>{data.mdx.frontmatter.salary}</span>
            </div>
            <div className="flex items-center w-[312px] h-[40px] bg-[#F9F9F9] rounded-2xl mb-5">
              <img className="w-3 h-3 mx-[10px]" src={locationIcon} alt="icon"></img>
              <span>{data.mdx.frontmatter.site}</span>
            </div>
            <div className="flex items-center mb-5">
              <span className="mx-[10px] text-base text-[#3F4650]">Share</span>
              <img className="mx-[10px] w-6 h-6" src={facebookIcon} alt="icon"></img>
              <img className="mx-[10px] w-6 h-6" src={twitterIcon} alt="icon"></img>
              <img className="mx-[10px] w-6 h-6" src={linkedIcon} alt="icon"></img>
            </div>
            <div className="flex items-center ">
              <div className="flex flex-col my-[20px]">
                <p className="text-[20px] my-[10px] font-bold text-[#2A2F36]   ">Apply now</p>
                <form onSubmit={sendEmail}>
                  <div className=" flex flex-col    w-[312px] mb-[16px] ">
                    <div className=" w-[312px] mx-auto md:mr-[16px]">
                      <div className="flex">
                        <p className="text-[16px] my-[10px] font-bold text-gray-400   ">Your Name </p>
                        <p className="text-[18px] my-[10px] text-red-500">*</p>
                      </div>
                      <input
                        className=" w-[312px] h-[50px] px-[20px] rounded-2xl border  hover:shadow"
                        type="text"
                        placeholder="Your Name"
                        name="yourName"
                        value={input.yourName || ''}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-[312px] mx-auto">
                      <div className="flex">
                        <p className="text-[16px] my-[10px] font-bold text-gray-400   ">Email </p>
                        <p className="text-[18px] my-[10px] text-red-500">*</p>
                      </div>
                      <input
                        className="w-[312px] h-[50px] px-[20px] rounded-2xl border  hover:shadow"
                        type="email"
                        placeholder="Email"
                        name="Email"
                        pattern=".+@gmail.com"
                        value={input.Email || ''}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className=" w-full  ">
                    <div className="flex">
                      <p className="text-[16px] mb-[10px] font-bold text-gray-400   ">Message </p>
                      <p className="text-[18px] text-red-500">*</p>
                    </div>
                    <textarea
                      className=" w-full h-[175px] px-[20px] rounded-2xl border py-2  hover:shadow"
                      rows={6}
                      cols={80}
                      id=""
                      placeholder="Message  to Skyline Tech..."
                      name="Message"
                      value={input.Message || ''}
                      onChange={handleChange}></textarea>
                    <button
                      className="w-[200px] h-[48px] bg-gradient-to-r from-cyan-500 to-teal-500 px-14 py-2 rounded-full text-white text-[16px] font-semibold mt-[16px] shadow hover:shadow-xl "
                      onClick={() => handleSend()}>
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const data = graphql`
  query ($slug: String) {
    mdx(slug: { eq: $slug }) {
      body
      frontmatter {
        career
        salary
        site
        time
        title
      }
    }
  }
`;

export default JobDetail;
